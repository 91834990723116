import * as React from 'react';
import Layout from '../components/general/layout';
import libraryStyle from '../less/library.module.less';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Progress,
  Slider,
  Space,
  Tag,
} from 'antd';
import ProjectCard from '../components/general/project-card';
import ThemeCard from '../components/general/themeCard';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import NewsCard from '../components/general/news-card';
import Container from '../components/general/container';
import CardGrid from '../components/general/card-grid';

const marks = {
  0: '0',
  100: '100',
};

const menu = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.alipay.com/"
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.taobao.com/"
      >
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
);

export default () => (
  <Layout
    heroTitle={'Component library'}
    heroSubtitle={'Pick your components'}
    heroBgColor={'var(--color-ternary)'}
  >
    <Container>
      <h1>Component Library</h1>
      <section>
        <h2>Typography</h2>
        <p>I'm a regular paragraph.</p>
        <p>
          I'm <b>bold</b> text.
        </p>
        <h1>H1</h1>
        <h2>H2</h2>
        <h3>H3</h3>
        <h4>H4</h4>
        <h5>H5</h5>
        <h6>H6</h6>
      </section>

      <section>
        <h2>Buttons</h2>
        <Space>
          <Button>Default</Button>
          <Button type="primary">Primary</Button>
          <Button type="dashed">Dashed</Button>
          <Button type="link">Link</Button>
          <Button icon={<SearchOutlined />}>Button with icon</Button>
        </Space>
      </section>

      <section>
        <h2>Dropdowns</h2>
        <Dropdown overlay={menu}>
          <button className="ant-dropdown-link">
            <span>NL</span>
            <DownOutlined />
          </button>
        </Dropdown>
      </section>

      <section>
        <h2>Inputs</h2>

        <div className={libraryStyle.mb1}>
          <Input type="text" placeholder="Just some placeholdertext" />
        </div>

        <div className={libraryStyle.mb1}>
          <Input type="text" placeholder="Just some placeholdertext" />
        </div>

        <Input
          placeholder="Input with prefixed icon"
          prefix={
            <SearchOutlined
              style={{ color: 'var(--color-primary)', fontSize: '15px' }}
            />
          }
        />
      </section>

      <section>
        <h2>Tags</h2>
        <Tag color="#f50">Tag 1</Tag>
        <Tag color="#f50">Tag 2</Tag>
        <Tag color="#f50">Tag 3</Tag>
      </section>

      <section>
        <h2>Sliders</h2>
        <h4>Quiz Slider</h4>
        <Slider marks={marks} defaultValue={0} />
      </section>

      <section>
        <h2>Progress bars</h2>
        <Progress percent={25} successPercent={0} showInfo={false} />
        <Progress percent={50} successPercent={25} showInfo={false} />
        <Progress percent={75} successPercent={50} showInfo={false} />
        <Progress percent={100} successPercent={75} showInfo={false} />
      </section>

      <section>
        <h2>Cards</h2>
        <h4>Project Card in grid</h4>
        <div className={libraryStyle.mb1}>
          <CardGrid>
            <ProjectCard
              title={'International Jazz Day'}
              description={
                'In november 2011 riep UNESCO officieel 30 april uit als Internationale Jazzdag. Daarmee wil UNESCO het belang…'
              }
              theme={'Muziek'}
            />
            <ProjectCard
              title={'International Jazz Day'}
              description={
                'In november 2011 riep UNESCO officieel 30 april uit als Internationale Jazzdag. Daarmee wil UNESCO het belang…'
              }
              theme={'Muziek'}
            />
            <ProjectCard
              title={'International Jazz Day'}
              description={
                'In november 2011 riep UNESCO officieel 30 april uit als Internationale Jazzdag. Daarmee wil UNESCO het belang…'
              }
              theme={'Muziek'}
            />
            <ProjectCard
              title={'International Jazz Day'}
              description={
                'In november 2011 riep UNESCO officieel 30 april uit als Internationale Jazzdag. Daarmee wil UNESCO het belang…'
              }
              theme={'Muziek'}
            />
            <ProjectCard
              title={'International Jazz Day'}
              description={
                'In november 2011 riep UNESCO officieel 30 april uit als Internationale Jazzdag. Daarmee wil UNESCO het belang…'
              }
              theme={'Muziek'}
            />
            <ProjectCard
              title={'International Jazz Day'}
              description={
                'In november 2011 riep UNESCO officieel 30 april uit als Internationale Jazzdag. Daarmee wil UNESCO het belang…'
              }
              theme={'Muziek'}
            />
          </CardGrid>
        </div>

        <h4>Theme Card</h4>
        <div className={libraryStyle.mb1}>
          <ThemeCard
            title={'Verrichtingen op het gebied van de overheidsschuld'}
            amount={'1000000'}
            hasOptions={true}
            projectsTranslation={'3 projecten'}
            infoTranslation={'Meer info'}
          />
        </div>
        <div className={libraryStyle.mb1}>
          <ThemeCard
            title={'Verrichtingen op het gebied van de overheidsschuld'}
            amount={''}
            hasOptions={false}
          />
        </div>
        <h4>News Card</h4>
        <NewsCard
          title={'Waarin investeren gemeenten?'}
          description={
            'Om hun verschillende diensten te kunnen uitoefenen, beheren gemeenten een aanzienlijk patrimonium in de vorm van gebouwen en terreinen: zoals gemeentehuizen, sporthallen, bibliotheken, culturele centra en zwembaden. Daarnaast beheren zij ook straatmeubilair en de openbare verlichting, en moeten zij voertuigen, computers en kantoormeubilair voorzien voor hun personeel. Het onderhouden, vernieuwen of bouwen van die eigendommen vereist belangrijke investeringen…'
          }
        />
      </section>
    </Container>
  </Layout>
);
